import React from "react"
import styled from "styled-components"

const Navbar = () => {
  return (
    <Container>
      <p>Colin Brown</p>
    </Container>
  )
}

export default Navbar

const Container = styled.div`
  margin: 0 10px;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  font-family: "mukta", sans-serif;
  font-size: 2.2rem;
`
