import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const Projects = () => {
  return (
    <Container>
      <Image>
        <a
          href="https://bounce-haus.vercel.app/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../images/bounce-haus-ss.png"
            width={600}
            quality={100}
          />
        </a>
      </Image>
      <Image>
        <a
          href="https://giovanni-vitali.netlify.app/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage src="../images/gv-ss.png" width={600} quality={100} />
        </a>
      </Image>
      <Image>
        <a href="https://finnick.netlify.app/" target="_blank" rel="noreferrer">
          <StaticImage
            src="../images/finnick-ss.png"
            width={600}
            quality={100}
          />
        </a>
      </Image>
      <Image>
        <a
          href="https://bark-leave-a-message.herokuapp.com/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage src="../images/bark-ss.png" width={600} quality={100} />
        </a>
      </Image>
      <Image>
        <a
          href="https://rest-countries-webapp.vercel.app/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage src="../images/rc-ss.png" width={600} quality={100} />
        </a>
      </Image>
    </Container>
  )
}

export default Projects

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (min-width: 1000px) {
    flex-direction: row;
  }
`

const Image = styled.div`
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.25);
  margin: 30px 0;

  @media screen and (min-width: 800px) {
    margin: 30px 25px;
  }
`
