import React from "react"
import Layout from "../components/Layout"
import Home from "../components/Home"
import Projects from "../components/Projects"

const Index = () => {
  return (
    <Layout>
      <Home />
      <Projects />
    </Layout>
  )
}

export default Index
