import React from "react"
import styled from "styled-components"

const Home = () => {
  return (
    <Container>
      <h1>Hi!</h1>
      <p>
        I’m Colin Brown. I enjoy developing clean, easy to use, and performant
        front-ends with React and Gatsby.
      </p>
      <p>Check out a few of my projects below:</p>
      <NoteContainer>
        <p style={{ fontSize: "1.2rem" }}>
          I like to share my work as it progresses, so you may see a few things
          that don't seem fully done
        </p>
        <p style={{ fontSize: "1.2rem" }}>
          Bark: it may take 10-15 seconds for Heroku's servers to wake up
        </p>
        <p style={{ fontSize: "1.2rem" }}>
          Country flags: utilizes the{" "}
          <a href="https://restcountries.eu/">REST Countries API</a>
        </p>
      </NoteContainer>
    </Container>
  )
}

export default Home

const Container = styled.div`
  margin: 0 10px;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  font-family: "mukta", sans-serif;
  font-weight: 200;
  font-size: 1.5rem;

  h1 {
    font-family: "gurajada", serif;
    font-size: 8rem;
    margin-top: -50px;
    margin-bottom: -60px;
  }
`

const NoteContainer = styled.div`
  background: #deebff;
  padding: 15px;
  border-radius: 10px;
`
